import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Row, Col, message} from 'antd';
import {FacebookProvider, LoginButton} from 'react-facebook';
import {navigate} from 'gatsby';
import logoBackground from '~images/logo-background.svg';
import {login as loginConsumer, register as registerConsumer} from '~actions/user';
import {UserProviders} from '~utils/enum';
import Loading from '~components/page-loader';
import FormLogin from './form-login';
import FormRegister from './form-register';
import GoogleLogin from '~components/google-login';

import './styles.scss';

const Login = props => {
  const [isLogin, setLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const responseFacebook = response => {
    const {loginConsumer} = props;
    const tokenValue = response?.tokenDetail?.accessToken;
    if (tokenValue) {
      const data = {provider: UserProviders.Facebook, token: tokenValue};
      loginConsumer(data);
      setLoading(true);
    }
  };

  const responseGoogle = token => {
    if (!token) {
      message.error('Đã có lỗi xảy ra vui lòng thử lại!', 5);
    }

    const {loginConsumer} = props;
    const data = {provider: UserProviders.Google, token};
    loginConsumer(data);
    setLoading(true);
  };

  const handleError = error => {
    console.log(error);
  };

  const handleClick = () => navigate('/register');

  // redirect if login success
  useEffect(() => {
    setLoading(false);
    if (props.user && props.user.token) {
      navigate('/home');
    }
  }, [props.user]);

  return (
    <Row className='login-page' justify='center'>
      <Loading loading={loading} />
      <Col className='wrap-row-login' xs={24} md={24} lg={22}>
        <Row>
          <Col className='col-login-padding' xs={24} md={12} lg={12}>
            <div className='b-rentzy-logo'>
              <img src={logoBackground} alt='Rentzy background' onClick={() => navigate('/home/')} />
            </div>
          </Col>
          <Col className='col-login-padding' xs={24} md={12} lg={12}>
            <div className='form'>
              <Col xs={24} md={24} lg={24}>
                {isLogin && (
                  <>
                    <h3>Đăng nhập</h3>
                    <FormLogin {...props} setLoading={setLoading} />
                  </>
                )}
                {!isLogin && (
                  <>
                    <h3>Đăng ký</h3>
                    <FormRegister {...props} setLoading={setLoading} />
                  </>
                )}
              </Col>
              <div className='divider'>
                <div className='divider-line' />
                <span className='divider-text'>HOẶC</span>
                <div className='divider-line' />
              </div>
              <Col xs={24} md={24} lg={24}>
                <Row gutter={8} className='social-button'>
                  <Col xs={24} md={12} lg={12}>
                    <FacebookProvider appId={process.env.GATSBY_FACEBOOK_APP_ID || 'app_id'}>
                      <LoginButton
                        scope='email'
                        className='facebook-btn'
                        onCompleted={responseFacebook}
                        onError={handleError}
                      >
                        <span>Facebook</span>
                      </LoginButton>
                    </FacebookProvider>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <GoogleLogin
                      clientId={process.env.GATSBY_GOOGLE_CLIENT_ID}
                      onSuccess={responseGoogle}
                      onFailure={handleError}
                    />
                  </Col>
                </Row>
              </Col>
              {isLogin && (
                <Col className='register-quotes' xs={24} md={24} lg={24}>
                  Bạn chưa có tài khoản? <a onClick={handleClick}>Đăng ký</a>
                </Col>
              )}
              {!isLogin && (
                <Col className='register-quotes' xs={24} md={24} lg={24}>
                  Bạn đã có tài khoản? <a onClick={handleClick}>Đăng nhập</a>
                </Col>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default connect(
  state => ({
    user: state.user.user,
  }),
  dispatch => ({
    loginConsumer: values => dispatch(loginConsumer(values)),
    registerConsumer: values => dispatch(registerConsumer(values)),
  })
)(Login);
