import React from 'react';
import {Button, Form, Input} from 'antd';
import {UserProviders} from '~utils/enum';

const FormLogin = props => {
  const onFinish = values => {
    const {loginConsumer, setLoading} = props;
    if (values) {
      const data = {provider: UserProviders.Default, ...values};
      loginConsumer(data);
      setLoading(true);
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Form name='loginform' className='login-form' onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item name='username' rules={[{required: true, message: 'Vui lòng điền vào mục này!'}]}>
        <Input placeholder='Tên tài khoản/ email/ số điện thoại' />
      </Form.Item>

      <Form.Item name='password' rules={[{required: true, message: 'Vui lòng nhập mật khẩu!'}]}>
        <Input.Password placeholder='Nhập mật khẩu' />
      </Form.Item>

      <Form.Item>
        <Button className='btn-submit' type='primary' htmlType='submit'>
          Đăng Nhập
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormLogin;
