import React from 'react';
import Layout from '~components/layout';
import Seo from '~components/seo';
import Login from '~containers/authenticate/login';

const LoginPage = () => {
  return (
    <Layout isLoginPage>
      <Seo title='Login' />
      <Login />
    </Layout>
  );
};

export default LoginPage;
