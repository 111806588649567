import React from 'react';
import {Button, Form, Input} from 'antd';

const FormRegister = props => {
  const onFinish = values => {
    const {registerConsumer, setLoading} = props;
    if (values) {
      registerConsumer(values);
      setLoading(true);
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <Form name='registerform' className='login-form' onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item name='fullname' rules={[{required: true, message: 'Vui lòng nhập họ và tên!'}]}>
        <Input placeholder='Nhập họ và tên' />
      </Form.Item>
      <Form.Item name='username' rules={[{required: true, min: 5, max: 32, message: 'Vui lòng nhập tên tài khoản!'}]}>
        <Input placeholder='Nhập tên tài khoản' />
      </Form.Item>
      <Form.Item
        name='email'
        rules={[
          {
            type: 'email',
            message: 'Sai định dạng email',
          },
          {required: true, message: 'Vui lòng nhập email!'},
        ]}
      >
        <Input placeholder='Nhập email' />
      </Form.Item>
      <Form.Item name='mobileno' rules={[{required: true, message: 'Vui lòng nhập số điện thoại!'}]}>
        <Input placeholder='Nhập số điện thoại' />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập mật khẩu!',
          },
          {
            min: 7,
            message: 'Mật khẩu phải có ít nhất 7 kí tự!',
          },
          {
            max: 32,
            message: 'Mật khẩu không được quá 32 kí tự!',
          },
        ]}
      >
        <Input.Password placeholder='Nhập mật khẩu' />
      </Form.Item>

      <Form.Item>
        <Button className='btn-submit' type='primary' htmlType='submit'>
          Đăng Ký
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormRegister;
